@font-face {
    font-family: "Raleway-Black";
    src: url("./Raleway-Black.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-BlackItalic";
    src: url("./Raleway-BlackItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-Bold";
    src: url("./Raleway-Bold.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-BoldItalic";
    src: url("./Raleway-BoldItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-ExtraBold";
    src: url("./Raleway-ExtraBold.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-ExtraBoldItalic";
    src: url("./Raleway-ExtraBoldItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-ExtraLight";
    src: url("./Raleway-ExtraLight.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-ExtraLightItalic";
    src: url("./Raleway-ExtraLightItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-Italic";
    src: url("./Raleway-Italic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-Light";
    src: url("./Raleway-Light.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-LightItalic";
    src: url("./Raleway-LightItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-Medium";
    src: url("./Raleway-Medium.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-MediumItalic";
    src: url("./Raleway-MediumItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-Regular";
    src: url("./Raleway-Regular.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-SemiBold";
    src: url("./Raleway-SemiBold.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-SemiBoldItalic";
    src: url("./Raleway-SemiBoldItalic.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-Thin";
    src: url("./Raleway-Thin.ttf");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "Raleway-ThinItalic";
    src: url("./Raleway-ThinItalic.ttf");
    font-style: normal;
    font-weight: normal;
}
